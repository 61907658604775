import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, vShow as _vShow, normalizeClass as _normalizeClass, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "p-2 infobox" }
const _hoisted_2 = {
  key: 0,
  class: "fas fa-info-circle text-infobox",
  style: {"padding-right":"10px","font-size":"1.4em"}
}
const _hoisted_3 = {
  key: 1,
  class: "fas fa-exclamation-circle text-warning",
  style: {"padding-right":"10px","font-size":"1.4em"}
}
const _hoisted_4 = {
  key: 2,
  class: "fas fa-exclamation-circle text-danger",
  style: {"padding-right":"10px","font-size":"1.4em"}
}
const _hoisted_5 = { class: "d-flex flex-stack flex-grow-1" }
const _hoisted_6 = { class: "fs-6 text-gray-700" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("div", {
      class: _normalizeClass([_ctx.clazz, "notice d-flex rounded border border-dashed p-6"])
    }, [
      _createElementVNode("div", null, [
        (_ctx.type==='info')
          ? (_openBlock(), _createElementBlock("i", _hoisted_2))
          : _createCommentVNode("", true),
        (_ctx.type==='warn')
          ? (_openBlock(), _createElementBlock("i", _hoisted_3))
          : _createCommentVNode("", true),
        (_ctx.type==='alert')
          ? (_openBlock(), _createElementBlock("i", _hoisted_4))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("span", null, [
            _renderSlot(_ctx.$slots, "default")
          ])
        ])
      ])
    ], 2), [
      [_vShow, _ctx.show]
    ])
  ]))
}